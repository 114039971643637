import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import useSiteMetadata from "./_useSiteMetadata";

import goodSansRegular from "assets/fonts/goodsans-regular.woff2";
import goodSansBold from "assets/fonts/goodsans-bold.woff2";

const SEO = ({ ...data }) => {
  const { siteMetadata } = useSiteMetadata();

  /* Merge siteMetadata with props */
  const { title, siteName, description, image, siteUrl, slug } = Object.assign(
    {},
    siteMetadata,
    data
  );

  /* Generate a full URL to the content & image path */
  const url = slug ? siteUrl + slug : siteUrl;
  const imageUrl = image.startsWith("/") ? siteUrl + image : image;

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      titleTemplate={`%s | ${siteName}`}
      defaultTitle={siteName}
    >
      <title>{title}</title>

      {/* Resource Hints */}
      <link rel="preconnect dns-prefetch" href="https://ucarecdn.com" />
      <link
        rel="preconnect dns-prefetch"
        href="https://www.google-analytics.com"
      />

      <link rel="preload" href={goodSansRegular} as="font" crossorigin="true" />
      <link rel="preload" href={goodSansBold} as="font" crossorigin="true" />

      <meta name="description" content={description} />

      {/* Open Graph Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${title} | ${siteName}`} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={imageUrl} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image" content={imageUrl} />

      {/* Google Search Console */}
      <meta
        name="google-site-verification"
        content="HHLwzSZzpjD1VAaf37WQyTMawoSfVFFtqPXVT3pThJc"
      />

      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6274290636964768"
        crossorigin="anonymous"
      />
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2512383459692373"
        crossorigin="anonymous"
      />
    </Helmet>
  );
};

SEO.propTypes = {
  data: PropTypes.object,
};

export default SEO;
